import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import VueFeather from 'vue-feather';
// import { createI18n } from 'vue-i18n'
import en from './locales/en.json';
import pt from './locales/fr.json';
 import fr from './locales/pt.json';
import es from './locales/es.json';
import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons';

// import config so we can set a default style
import { config } from "@fortawesome/fontawesome-svg-core";
import VueDiff from 'vue-diff';


import 'vue-diff/dist/index.css';


const messages = { en: en, es: es, pt: pt, fr: fr};
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
library.add(fas);

// set the default style
config.styleDefault = "duotone";


createApp(App).use(router).use(store).use(VueDiff)
    .component(VueFeather.name, VueFeather)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Breadcrumbs', Breadcrumbs)
    .mount('#app');

const mode = process.env.NODE_ENV;
const vueAppApiUrl = document.vueAppApiUrl ?? null;
axios.defaults.baseURL = (mode === 'production' && !vueAppApiUrl)
    ? "https://contribee.com/api"
    : vueAppApiUrl ?? process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
// Set up a request interceptor
axios.interceptors.request.use(
    function(config) {
        const token = store.state.token || localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers["Content-Type"] = "application/json";
        config.headers["Accept"] = "application/json";
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// Set up a response interceptor
axios.interceptors.response.use(
    function(response) {
        // If the response status is in the 2xx range, return it as is
        return response;
    },
    function(error) {
        if (error.response) {
            if (error.response.status === 422) {
                store.commit("SET_VALIDATION_ERRORS", error.response.data.errors);
            } else if (error.response.status === 404) {
                router.push({name: "404"});
            }
            else if (error.response.status === 401) {
                store.commit("SET_USER", null);
                store.commit("SET_TOKEN", null);
                store.commit("SET_VALIDATION_ERRORS", []);
                localStorage.removeItem("token");
                router.push({ name: "login" });
            } else {
                console.log("HTTP error:", error.response.status, error.response.data, error.message);
            }
        } else if (error.request) {
            console.log("Network error:", error.message);
        } else {
            console.log("Error:", error.message);
        }

        return Promise.reject(error);
    }
);