<template>
  <div class="container">
    <br />
    <div class="row">
      <div class="button-group" role="group">
        <a @click="filterDate('today')" type="button" class="btn btn-dark-purple mb-3 me-2">Today</a>
        <a @click="filterDate('thisMonth')" type="button" class="btn btn-dark-purple mb-3 me-2">This month</a>
        <a @click="filterDate('thisYear')" type="button" class="btn btn-dark-purple mb-3 me-2">This year</a>
        <a @click="filterDate('total')" type="button" class="btn btn-dark-purple mb-3 me-2">Total</a>
        <button type="button" class="btn btn-dark-purple mb-3 me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
          Custom 
        </button>
      </div>
      <span v-if="loading"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></span>
      <p v-else>Date filter {{fromDate}} - {{toDate}}</p>
      <br/>
      <div v-for="(card, index) in cards" :key="index" class="col-lg-4 col-md-6">
        <div class="card" data-bs-toggle="modal" data-bs-target="#categoryModal" v-if="index === 5">
          <div class="card-body">
            <h5 class="card-title">{{ card.title }}</h5>
            <p class="card-text"  >
              {{ card.value }}
            </p>
            <p class="card-text">More &#11206;</p>
          </div>
        </div>
        <div class="card" v-else-if="index >= 6 && index <= 13">
          <div class="card-body">
            <h5 class="card-title">{{ card.title }}</h5>
            <p class="card-text" >
              {{ card.value }}
            </p>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-body">
            <h5 class="card-title">{{ card.title }}</h5>
            <p class="card-text" v-if="card.title !== 'Total payments' && card.title !== 'Total active fans'">
              {{ card.value }} {{selectedCurrencySymbol}}
            </p>
            <p class="card-text" v-else>
              {{ card.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Dashboard date filter</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="fromDate" class="form-label">From date</label>
            <input type="date" class="form-control me-1" id="fromDate" v-model="fromDate" @input="formatCustomDate('fromDate')">
          </div>
          <div class="mb-3">
            <label for="toDate" class="form-label">To date</label>
            <input type="date" class="form-control me-1" id="toDate" v-model="toDate" @input="formatCustomDate('toDate')">
          </div>
          <div class="mb-3">
            <label for="currency" class="form-label">Currency</label>
            <select class="form-control" id="currency" v-model="selectedCurrency">
              <option v-for="currency in currencies" :value="currency.currency">{{currency.currency}}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="fetchDate(fromDate, toDate, selectedCurrency)" type="button" class="btn btn-dark-purple me-2">Search</button>
          <a @click="clearFilter" type="button" class="btn btn-dark-purple">Clear</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="categoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="categoryBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Active creators by categories</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="(category, index) in channelCategories" :key="index" class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{ category.name }}</h5>
                <p class="card-text">
                  {{ category.channels_count }}
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currencies: [],
      cards: [
        {
          title: 'Creator earnings',
          value: 0, //this is full value
        },
        {
          title: 'Platform earnings',
          startingValue: 0 - 100,
          value: 0,
        },
        {
          title: 'Total payments',
          value: 0,
        },
        {
          title: 'Average spending price',
          startingValue: 7.82,
          value: 7.82,
        },
        {
          title: 'Total active fans',
          value: 0,
        },
        {
          title: 'Total active creators',
          value: 0,
        },
        {
          title: 'New subscribers amount',
          value: 0,
        },
        {
          title: 'New subscribers count',
          value: 0,
        },
        {
          title: 'Cancelled subscribers amount',
          value: 0,
        },
        {
          title: 'Cancelled subscribers count',
          value: 0,
        },
        {
          title: 'New ppc amount',
          value: 0,
        },
        {
          title: 'New ppc count',
          value: 0,
        },
        {
          title: 'Cancelled ppc amount',
          value: 0,
        },
        {
          title: 'Cancelled ppc count',
          value: 0,
        },
      ],
      fromDate: '',
      toDate: '',
      channelCategories: [],
      selectedCurrency: 'EUR',
      selectedCurrencySymbol: '€',
      loading: true,
    };
  },
  methods: {
    filterDate(filterParam) {
      const date = new Date();
      if (filterParam === 'today') {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.fromDate = this.fromDate.toISOString().split('T')[0];
        this.toDate = this.toDate.toISOString().split('T')[0];
      }
      else if (filterParam === 'thisYear') {
        this.fromDate = new Date(date.getFullYear(), 0, 1);
        this.toDate = new Date(date.getFullYear(), 11, 31);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      }
      else if (filterParam === 'thisMonth') {
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      }
      else if (filterParam === 'total') {
        this.fromDate = new Date(2018, 0, 1);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(date);
      }
      this.fetchDate(this.fromDate, this.toDate, this.selectedCurrency);
    },
    clearFilter() {
      this.selectedCurrency = 'EUR';
      this.selectedCurrencySymbol = '€';
      this.filterDate('thisMonth');
    },
    fetchDate(fromDate, toDate, currency) {
      this.fetchActiveChannels(fromDate, toDate);
      this.fetchActiveFans(fromDate, toDate);
      this.fetchDashboardStats(fromDate, toDate, currency);
      this.fetchDashboardPledgeStats(fromDate, toDate);
      this.fetchDashboardPpcStats(fromDate, toDate);
    },
    fetchDashboardStats(fromDate, toDate, currency) {
      this.loading = true;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`/admin/dashboard?from_date=${fromDate}&to_date=${toDate}&currency=${currency}`)
          .then(response => {
            this.cards[1].value = response.data.platformEarnings;
            this.cards[0].value = response.data.platformRevenue;
            this.cards[2].value = response.data.totalRecords;
            this.cards[3].value = ((response.data.platformEarnings + response.data.platformRevenue) / response.data.totalRecords).toFixed(2);
            this.selectedCurrencySymbol = response.data.selectedCurrency;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    fetchDashboardPledgeStats(fromDate, toDate) {
      this.loading = true;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`/admin/dashboard/pledges?from_date=${fromDate}&to_date=${toDate}`)
          .then(response => {
            this.cards[6].value = response.data.newSubscribersAmount;
            this.cards[7].value = response.data.newSubscribersCount;
            this.cards[8].value = response.data.cancelledSubscribersAmount;
            this.cards[9].value = response.data.cancelledSubscribersCount;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    fetchDashboardPpcStats(fromDate, toDate) {
      this.loading = true;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`/admin/dashboard/ppc?from_date=${fromDate}&to_date=${toDate}`)
          .then(response => {
            this.cards[10].value = response.data.newSubscribersAmount;
            this.cards[11].value = response.data.newSubscribersCount;
            this.cards[12].value = response.data.cancelledSubscribersAmount;
            this.cards[13].value = response.data.cancelledSubscribersCount;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    fetchActiveChannels(fromDate, toDate) {
      this.loading = true;
      this.cards[5].value = 0;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`admin/dashboard/active/channels?from_date=${fromDate}&to_date=${toDate}`)
          .then(response => {
            this.channelCategories = response.data;
            this.channelCategories.forEach((el) => this.cards[5].value += el.channels_count)
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
    },
    fetchActiveFans(fromDate, toDate) {
      this.loading = true;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`admin/dashboard/active/fans?from_date=${fromDate}&to_date=${toDate}`)
          .then(response => {
            this.cards[4].value = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
    },
    fetchCurrencies() {
      axios.get(`/admin/currency`)
          .then(response => {
            this.currencies = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatCustomDate(date) {
      let dateValue = this[date];

      // Check if the input is a valid date
      if (dateValue) {
        // Convert the input to a Date object
        const dateObject = new Date(dateValue);
        this[date] = this.formatDate(dateObject);
      }
    }
  },
  mounted() {
    this.fetchCurrencies();
    this.filterDate('thisMonth');
  }
};
</script>
