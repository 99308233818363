<template>
  <br />
  <div class="row">
    <div class="alert alert-success" role="alert" v-if="successMessage" >
        {{ successMessage}}
    </div>
    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3">
      <button type="button" class="btn btn-primary" data-bs-target="#createContentCategoryModal" data-bs-toggle="modal"
        :disabled="disableCreate">
        <span v-if="!disableCreate">Create category</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin /></span>
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Position</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="contentCategories.length > 0" v-for="contentCategory in contentCategories">
          <td>{{contentCategory.id}}</td>
          <td>{{ contentCategory.name }}</td>
          <td>{{ contentCategory.position }}</td>
          <td>
              <button type="button" class="btn btn-info" data-bs-target="#updateContentCategoryModal" data-bs-toggle="modal"
                :disabled="disableCreate" @click="openUpdateContentCategoryModal(contentCategory.id)">
                <span v-if="!disableCreate">Edit</span>
                <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin /></span>
              </button>
          </td>
        </tr>
        <tr v-else-if="loading">
          <td colspan="7" class="text-center"><font-awesome-icon icon="fa-solid fa-sync" spin /></td>
        </tr>
        <tr v-else>
          <td colspan="7" class="text-center">No categories</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
      <div>
        <div class="p-2">
          <p>Total categories: {{ totalRecords }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="createContentCategoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Create content category form</h1>
          <button id="closeCreateContentCategoryBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control me-1" id="createContentCategoryName" v-model="createContentCategoryName">
            <p v-if="validationErrors" v-for="name in validationErrors.name" class="text-danger">{{ name }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="createContentCategory(createContentCategoryName)"
            type="button" class="btn btn-dark-purple" :disabled="disableCreate">
            <span v-if="!disableCreate">Create</span>
            <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin /></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="updateContentCategoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Update content category form <span v-if="disableCreate"><font-awesome-icon icon="fa-solid fa-sync" spin /></span></h1>
          <button id="closeUpdateContentCategoryBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input type="text" class="form-control me-1" id="updateContentCategoryId" v-model="updateContentCategoryId" hidden>
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control me-1" id="updateContentCategoryName" v-model="updateContentCategoryName">
            <p v-if="validationErrors" v-for="name in validationErrors.name" class="text-danger">{{ name }}</p>
          </div>
          <div class="mb-3">
            <label for="name" class="form-label">Position</label>
            <input type="number" class="form-control me-1" id="updateContentCategoryPosition" v-model="updateContentCategoryPosition">
            <p v-if="validationErrors" v-for="name in validationErrors.position" class="text-danger">{{ name }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button 
            @click="updateContentCategory(updateContentCategoryId, updateContentCategoryName, updateContentCategoryPosition)"
            type="button" class="btn btn-dark-purple" :disabled="disableCreate">
            <span v-if="!disableCreate">Update</span>
            <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin /></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
  import axios from 'axios';
  import _debounce from "lodash/debounce";
  
  export default {
    data() {
      return {
        loading: true,
        contentCategories: [],
        validationErrors : [],
        totalRecords: 0,
        disableCreate: false,
        successMessage: "",
        createContentCategoryName : "",
        updateContentCategoryId : "",
        updateContentCategoryName : "",
        updateContentCategoryPosition : ""
      }
    },
  
    mounted() {
        this.getContentCategories();
    },
  
    methods: {
      debounceGetContentCategories: _debounce(function () {
        this.loading = true;
        axios.get(`/admin/content-categories`)
        .then(response => {
          this.contentCategories = response.data;
          this.totalRecords = this.contentCategories.length
          this.loading = false;
        })
        .catch(error => {
            this.loading = false;
            console.log(error);
        });
      }, 500),
      closeModal() {
        document.querySelector('#closeUpdateContentCategoryBtn').click();
        document.querySelector('#closeCreateContentCategoryBtn').click();
      },
      getContentCategories() {
        this.debounceGetContentCategories();
      },
      openUpdateContentCategoryModal(id) {
        this.disableCreate = true;
        axios.get(`/admin/content-categories/${id}`)
        .then(response => {
          let responseData = response.data;
          this.updateContentCategoryId = responseData.id;
          this.updateContentCategoryName = responseData.name;
          this.updateContentCategoryPosition = responseData.position;
          this.disableCreate = false;
        })
        .catch(error => {
          console.log(error);
          this.disableCreate = false;
        });
      },
      createContentCategory(createContentCategoryName) {
        this.disableCreate = true;
        axios.post('/admin/content-categories/create', {
          'name' : createContentCategoryName,
        })
        .then(response => {
          this.successMessage = "Category created successfuly";
          this.getContentCategories();
          this.resetContentCategoryParams();
          this.resetvalidationErrors();
          this.disableCreate = false;
          this.closeModal();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
          this.disableCreate = false;
        });
      },
      updateContentCategory(updateContentCategoryId, updateContentCategoryName, updateContentCategoryPosition) {
        this.disableCreate = true;
        axios.put(`/admin/content-categories/update/${updateContentCategoryId}`, {
          'name' : updateContentCategoryName,
          'position' : updateContentCategoryPosition
        })
        .then(response => {
          this.successMessage = "Category content updated successfuly";
          this.getContentCategories();
          this.resetUpdateContentCategoryParams();
          this.resetvalidationErrors();
          this.disableCreate = false;
          this.closeModal();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
          this.disableCreate = false;
        });
      },
      resetUpdateContentCategoryParams() {
        this.updateContentCategoryId = "";
        this.updateContentCategoryName = "";
        this.updateContentCategoryPosition = "";
      },
      resetContentCategoryParams() {
        this.createContentCategoryName = "";
      },
      resetvalidationErrors() {
        this.validationErrors = [];
      }
  }

  }
  </script>