<template>
    <br/>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="search" class="form-label mt-2 me-2">Keyword</label>
        <input type="text" id="search" class="form-control" placeholder="Product title" v-model="keyword"
               @input="searchKeyword"/>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-1 mt-2">Per page</label>
        <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
          <option value="10" :value="perPage">10</option>
          <option value="20" :value="perPage">20</option>
          <option value="50" :value="perPage">50</option>
        </select>
      </div>
    <div class="table-responsive container-fluid">
      <h4 class="text-center">{{this.channelName}} Products</h4>
      <router-link :to="{name: 'SingleChannel', params: {id: this.$route.params.id}}">Back to creator</router-link>
      <table class="table">
        <thead>
        <tr>
          <th scope="col"># ID</th>
          <th scope="col">Image</th>
          <th scope="col">Title</th>
          <th scope="col">Type</th>
          <th scope="col">Quantity</th>
          <th scope="col">Price</th>
          <th scope="col">Subscription price</th>
          <th scope="col">Text link</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="products.length > 0" v-for="product in products">
          <th scope="row">{{product.id}}</th>
          <td><img style="width:150px;" v-if="product.image" v-bind:src="product.image"></td>
          <td>{{product.title}}</td>
          <td>{{product.type}}</td>
          <td>{{product.quantity}}</td>
          <td>{{product.price / 100}} {{ product.currency }}</td>
          <td>{{product.subscription_price / 100}} {{ product.currency }}</td>
          <td>{{product.text_link}}</td>
        </tr>
        <tr v-else-if="loading">
          <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
        </tr>
        <tr v-else>
          <td colspan="8" class="text-center">No Products</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
        <div>
          <div class="p-2">
            <p>Total products: {{ totalRecords }}</p>
          </div>
        </div>
        <div>
          <div class="p-2">
            <nav v-if="totalPages >= 1" aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                  <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
                </li>
                <li class="page-item" :class="{active: 1 === currentPage}">
                  <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
                </li>
                <li class="page-item" v-if="currentPage - 2 > 1">
                  <a href="#" class="page-link">...</a>
                </li>
                <li class="page-item" v-if="currentPage - 2 > 1">
                  <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
                </li>
                <li class="page-item" v-if="currentPage - 1 > 1">
                  <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
                </li>
                <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                  <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
                </li>
                <li class="page-item" v-if="currentPage + 1 <= totalPages">
                  <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
                </li>
                <li class="page-item" v-if="currentPage + 2 <= totalPages">
                  <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
                </li>
                <li class="page-item" v-if="currentPage + 2 < totalPages">
                  <a href="#" class="page-link">...</a>
                </li>
                <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                  <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
                </li>
                <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                  <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import _debounce from "lodash/debounce";
  
  export default {
    data() {
      return {
        loading: true,
        keyword: '',
        products: [],
        channelName: '',
        currentPage: 1,
        totalPages: 0,
        totalRecords: 0,
        perPage: 20
      }
    },
  
    mounted() {
      this.fetchChannelName();
      this.getProducts('', 1, 20);
    },
  
    methods: {
      debounceGetProducts: _debounce(function (keyword, page, perPage) {
        this.products = [];
        this.loading = true;
        axios.get(`/admin/products/channel/${this.$route.params.id}?keyword=${keyword}&page=${page}&per_page=${perPage}`).then(response => {
          this.products = response.data.data;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
      }, 500),
      getProducts(keyword,  page, perPage) {
        this.debounceGetProducts(keyword, page, perPage);
      },
      searchKeyword() {
        this.getProducts(this.keyword, this.currentPage, this.perPage);
      },
      perPageFilter(perPage) {
        this.perPage = perPage;
        this.getProducts(this.keyword, this.currentPage, this.perPage);
      },
      paginate(page) {
        console.log('paginate');
        this.currentPage = page;
        this.getProducts(this.keyword, this.currentPage, this.perPage);
      },
      fetchChannelName() {
          axios.get(`/admin/channel-name/${this.$route.params.id}`)
          .then(response => {
            this.channelName = response.data.data;
          })
          .catch(error => {
            this.channelName = '-';
          });
      }
    }
  }
  </script>
  